<template>
  <div class="auth__container">
    <div class="auth_inner__container">
      <div class="auth_inner__wrapper">
        <div class="auth_inner__logo">
          <Logo />
        </div>
        <h2 class="auth_inner__title">
          Welcome to Preava Prevent
        </h2>
        <p class="auth_inner__message">
          You are 30 seconds away from protecting your outbound emails. 
          <a
            href="https://www.preava.com/"
            target="_blank"
            class="auth_inner__link"
          >Learn More</a>.
        </p>
        <div class="mt-6">
          <button
            @click="loginClick()"
            type="submit"
            :disabled="!isButtonEnabled"
            :class="[
              !isButtonEnabled ? 
              'auth_button__login--inactive' : 
              'auth_button__login--active' ,
              'auth_button__login',
            ]"
          >
            {{ buttonText }}
          </button>
          <p class="auth_inner__cta">
            Don't have an account?
            {{ " " }}
            <a
              href="https://www.preava.com/contact"
              target="_blank"
              class="auth_inner__link"
            >Contact Us</a>.
          </p>
        </div>
      </div>
    </div>
    <div class="auth_outer__container">
      <a
        href="https://www.preava.com/legal/privacy-notice"
        target="_blank"
        class="auth_outer__link"
        >Privacy Policy</a
      >
      <span class="auth_outer__separator">|</span>
      <a
        href="https://www.preava.com/legal/application-privacy-notice"
        target="_blank"
        class="auth_outer__link"
        >Application Privacy Policy</a
      >
    </div>
  </div>
</template>
<script setup>
// import Logo from "@/assets/logos/PreavaLogo.vue";
import Logo from "@/assets/logos/PreavaLogoBlue.vue";
import { ref } from "vue";
import { loginWithRedirect } from "@/utilities";

const buttonText = ref('Continue');
const isButtonEnabled = ref(true);

const loginClick = () => {
  isButtonEnabled.value = false;
  buttonText.value = "Please wait...";
  loginWithRedirect();
}

</script>
<style scoped>
  @import './auth.css';
</style>