<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    height="414"
    viewBox="0 0 1442 414"
    width="1442"
  >
    <path
      clip-rule="evenodd"
      d="m192.952 58.8956c47.876-4.8875 105.835-18.9051 148.296-58.8956-7.923 23.7666-19.771 41.6293-33.99 54.9691 10.13-6.2957 19.809-13.5948 28.793-22.0568-10.296 30.8837-27.218 51.7981-47.361 65.7736 5.9 10.8221 9.253 23.2331 9.253 36.4271v81.258c0 46.964-38.073 85.036-85.037 85.036h-59.055c-23.717 0-42.947 19.205-42.989 42.912v34.144c0 10.07 3.64 19.801 10.248 27.401 2.764 3.179.506 8.136-3.708 8.136h-9.761c-59.4483 0-107.64092033-48.175-107.6408664-107.624l.0000277-36.861-.0001613-.146v-123.862c0-47.8342 38.7772-86.6114 86.6112-86.6114zm-50.91 107.3984c-17.22 0-31.18 13.959-31.18 31.18h46.77c17.221 0 31.18-13.96 31.18-31.18z"
      fill="#3676ca"
      fill-rule="evenodd"
    />
    <path
      d="m297.077 122.988c-44.997 18.321-102.919 18.409-136.845 16.163 77.211-1.71 128.563-40.4137 128.563-40.4137 4.818 8.6607 6.55 15.5897 8.282 24.2507z"
      fill="#1f5baa"
    />
    <path
      d="m188.762 166.298c25.874-.073 53.218-1.26 83.147-14.728-29.224 19.769-59.512 33.647-89.158 33.779 2.073-4.173 6.01-14.075 6.011-19.051z"
      fill="#1f5baa"
    />
    <path
      d="m110.863 197.474h39.841c-25.117 11.26-35.51 22.519-39.841 128.185z"
      fill="#1f5baa"
    />
    <g fill="#3676ca">
      <path
        d="m1363.95 175.773v-25.159h12.17c1.78 0 3.34.341 4.71 1.022 1.38.682 2.45 1.648 3.2 2.898.78 1.25 1.16 2.75 1.16 4.5 0 1.773-.4 3.284-1.19 4.534-.8 1.227-1.9 2.171-3.31 2.83-1.38.636-2.99.954-4.81.954h-8.18v-4.943h6.89c1.02 0 1.85-.284 2.49-.852.63-.568.95-1.409.95-2.523 0-1.159-.32-2-.95-2.523-.64-.545-1.46-.818-2.46-.818h-3.78v20.08zm16.37-11.523 5.55 11.523h-7.53l-5.11-11.523zm-6.38 26.523c-3.77 0-7.31-.705-10.6-2.114-3.3-1.409-6.19-3.364-8.69-5.864s-4.46-5.397-5.87-8.693c-1.41-3.295-2.11-6.829-2.11-10.602s.7-7.307 2.11-10.602c1.41-3.296 3.37-6.193 5.87-8.693s5.39-4.455 8.69-5.864c3.29-1.409 6.83-2.114 10.6-2.114s7.31.705 10.6 2.114c3.3 1.409 6.2 3.364 8.7 5.864s4.45 5.397 5.86 8.693c1.41 3.295 2.11 6.829 2.11 10.602s-.7 7.307-2.11 10.602c-1.41 3.296-3.36 6.193-5.86 8.693s-5.4 4.455-8.7 5.864c-3.29 1.409-6.83 2.114-10.6 2.114zm0-7.432c3.68 0 7.02-.886 10.02-2.659 3-1.796 5.39-4.193 7.16-7.193 1.8-3 2.7-6.33 2.7-9.989s-.9-6.989-2.7-9.989c-1.77-3-4.17-5.386-7.19-7.159-3-1.795-6.33-2.693-9.99-2.693s-6.99.898-9.99 2.693c-3 1.773-5.39 4.159-7.19 7.159-1.77 3-2.66 6.33-2.66 9.989s.89 6.989 2.66 9.989c1.8 3 4.19 5.397 7.19 7.193 3 1.773 6.33 2.659 9.99 2.659z"
      />
      <path
        d="m480.488 190.775c11.378 0 21.258 1.917 29.642 5.749 8.384 3.713 14.851 9.102 19.402 16.169 4.551 6.946 6.827 15.15 6.827 24.612 0 9.461-2.276 17.665-6.827 24.612-4.551 6.946-11.018 12.336-19.402 16.168-8.384 3.713-18.264 5.569-29.642 5.569h-21.917v32.876h-35.571v-125.755zm-2.156 64.854c7.306 0 12.815-1.557 16.528-4.671 3.713-3.234 5.569-7.785 5.569-13.653 0-5.869-1.856-10.42-5.569-13.654-3.713-3.233-9.222-4.85-16.528-4.85h-19.761v36.828z"
      />
      <path
        d="m642.809 283.115h-19.403v33.415h-35.57v-125.755h57.488c11.377 0 21.258 1.917 29.642 5.749 8.383 3.713 14.851 9.102 19.402 16.169 4.551 6.946 6.827 15.15 6.827 24.612 0 9.102-2.156 17.066-6.468 23.893-4.192 6.707-10.24 11.977-18.144 15.809l27.127 39.523h-38.086zm22.456-45.81c0-5.869-1.857-10.42-5.569-13.654-3.713-3.233-9.222-4.85-16.528-4.85h-19.762v36.828h19.762c7.306 0 12.815-1.557 16.528-4.671 3.712-3.234 5.569-7.785 5.569-13.653z"
      />
      <path
        d="m855.915 289.044v27.486h-100.963v-125.755h98.628v27.487h-63.417v21.198h55.871v26.589h-55.871v22.995z"
      />
      <path
        d="m994.043 292.098h-53.176l-9.881 24.432h-36.289l55.512-125.755h35.032l55.689 125.755h-37.01zm-10.419-26.229-16.169-40.242-16.168 40.242z"
      />
      <path
        d="m1205.68 190.775-53.89 125.755h-35.03l-53.72-125.755h38.45l34.13 81.921 34.85-81.921z"
      />
      <path
        d="m1327.05 292.098h-53.18l-9.88 24.432h-36.29l55.51-125.755h35.04l55.69 125.755h-37.01zm-10.42-26.229-16.17-40.242-16.17 40.242z"
      />
    </g>
  </svg>
</template>